@import "./colors.scss";
@import "./tags.scss";
@import "./mixins";
@import "./styles.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  //font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  scroll-behavior: smooth;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

textarea {
  outline: none;
  resize: none;
  border: none;

  &:-webkit-autofill {
    &,
    &:hover,
    &:focus {
      border: none;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  vertical-align: baseline;
  background-color: var(--ar-grey);
  line-height: 1.3;
  position: relative;
  // height: 100vh;
  width: 100%;
  font-family: "Open Sans", sans-serif;

  // &::-webkit-scrollbar-track {
  //   --webkit-box-shadow: inset 0 0 6px #72bd2234;
  //   background-color: #ffffff;
  // }

  // &::-webkit-scrollbar {
  //   width: 6px;
  //   background-color: #ffffff;
  // }

  // &::-webkit-scrollbar-thumb {
  //   border-bottom-left-radius: 20px;
  //   border-bottom-right-radius: 20px;
  //   --webkit-box-shadow: inset 0 0 6px #72bd2234;
  //   background-color: #71BD22;
  // }
}

* {
  box-sizing: border-box;

  @include mobile {
    -webkit-tap-highlight-color: transparent;
  }
}

button:hover {
  animation-name: gradient;
  -webkit-animation-name: gradient;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

button {
  font-family: "Open Sans", sans-serif;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0 50%;
  }

  to {
    background-position: 100%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }

  to {
    background-position: 100%;
  }
}

::placeholder {
  font-family: "Open Sans", sans-serif;
}

input {
  font-family: "Open Sans", sans-serif;
}

main {
  position: relative;
  width: 100%;
  //  height: 100vh;
  // overflow-y: scroll;
  font-family: "Open Sans", sans-serif;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::placeholder {
  color: #ffffff;
  font-size: 13px;
}

img {
  object-fit: contain;
}

input {
  outline: none !important;
}

ul {
  list-style-position: outside;
  margin-left: 11px;
  font-size: 14px;

  li {
    padding-bottom: 4px;
    font-weight: 500;

    &:before {
      content: "\2022";
      /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: grey;
      /* Change the color */
      font-weight: bold;
      /* If you want it to be bold */
      display: inline-block;
      /* Needed to add space between the bullet and the text */
      width: 0.8em;
      /* Also needed for space (tweak if needed) */
      margin-left: -0.8em;
      /* Also needed for space (tweak if needed) */
    }
  }
}

.flex {
  display: flex;
}

.fdc {
  flex-direction: column;
}

.fdcr {
  flex-direction: column-reverse;
}

.fdr {
  flex-direction: row;
}

.fdrr {
  flex-direction: row-reverse;
}

.jcc {
  justify-content: center;
}

.jcfs {
  justify-content: flex-start;
}

.jcfe {
  justify-content: flex-end;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.jcse {
  justify-content: space-evenly;
}

.aic {
  align-items: center;
}

.aifs {
  align-items: flex-start;
}

.aife {
  align-items: flex-end;
}

.ais {
  align-items: stretch;
}

.aib {
  align-items: baseline;
}

.fww {
  flex-wrap: wrap;
}

.pointer {
  cursor: pointer;
}

.wb-100 {
  width: 100%;
}

.bld {
  font-weight: 600;
}

.rgl {
  font-weight: 400;
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.loading {
  display: flex;
  width: 30px;
  height: 30px;
  border: 2px solid #71bd22;
  border-radius: var(--br-circle);
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: relative;
  z-index: 1;
}

.loading-main-button {
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-radius: var(--br-circle);
  border-top-color: #71bd22;
  animation: spin 0.5s ease-in-out infinite;
  -webkit-animation: spin 0.5s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  z-index: 1;
}

.loading-button-overlay {
  z-index: 1050;
  width: 100%;
  height: 100%;
}

.loading-button {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid #71bd22;
  border-radius: var(--br-circle);
  border-top-color: white;
  animation: spin 0.5s ease-in-out infinite;
  -webkit-animation: spin 0.5s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: relative;
  z-index: 1;
}

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid #71bd22;
  border-radius: var(--br-circle);
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.grid {
  display: grid;
}
.item-description {
  h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    background: whitesmoke;
    padding: 16px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
  }
}
